 .slid1 .slick-slide {
    margin-left: 180px;
}




@media only screen and (max-width: 640px){
   

    .slid1 .slick-slide {
        margin-left: 20px;
    }
    
  }
  @media only screen and (max-width: 768px){
   
    .slid1 .slick-slide {
        margin-left: 20px;
    }
    
    
  }
 