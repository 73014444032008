.slick-C .slick-list{
    width: 43.7rem !important;
}
.slick-C .slick-list .slick-slide{
    width: 40rem !important;
    margin-right: -275px;
}
.slick-C .slick-list .slick-track{
    width: 19008px !important;
}


.slick-arrow .slick-next {

display: none !important;

}


.slick-prev, .slick-next {

    display: none !important;
}



.css05{
    display: flex;
}


.css06{
    width: 20rem;
    height: 10rem;
}



@media only screen and (max-width: 640px){
    .css01{
        display: flex;
        flex-direction: column;
    
    }
    .css02{
        width: 25% !important;
        display: inline-block;
    
    }
    .slick-C .slick-list .slick-slide {
        margin-right: -411px;
        width: 71rem !important;
    }
    .css04{
        padding-bottom: 5px !important;
    }
    .css03{
        width: 100% !important;
    }
    .css05{
        display: flex;
        flex-direction: column;
    }

    .Blogorg{
        padding-top: 80px;
        width: 100% !important;
        padding-bottom: 80px;
    }


    .css06{
        width: 20rem;
        height: 10rem;
    }
    .slick-C .slick-list{
        width: 45rem !important;
    }
        
  }
  @media only screen and (max-width: 768px){
    .css01{
        display: flex;
        flex-direction: column;
    }

    .css02{
        width: 25% !important;
        display: inline-block;
    
    }
    .css03{
        width: 100% !important;

    }
    .css04{
        padding-bottom: 5px !important;
    }

    .css05{
        display: flex;
        flex-direction: column;
    }
    
    .css06{
        width: 20rem;
        height: 10rem;
    }

    .slick-C .slick-list .slick-slide {
        margin-right: -411px;
        width: 71rem !important;
    }
    .Blogorg{
    
        padding-top: 80px;
        width: 100% !important;
        padding-bottom: 80px;
    }
    .slick-C .slick-list{
    width: 45rem !important;
}
}