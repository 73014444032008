.silck-list{
height: 100vh;
}
.slick-slider.slick-initialized.silck-list{
    padding-left: 330px;

}

.slick-slide {
    float: right;
    height: 100%;
    min-height: 1px;
    width: auto;
}

.css07{
    width: 20rem !important;
    height: 15rem !important;
    object-fit: fill !important;
}

@media only screen and (max-width: 640px){
    .slick-list{
     height: 550px;
        width: 43.5rem !important;
            padding-left: 0px;
            
        }

         .css08{
        width: 25% !important;
        display: inline-block;
    
    }
    .css09{
        padding-right: 20px;
    }
  }
  @media only screen and (max-width: 768px){
    .slick-slider .slick-initialized .slick-list{
        height: 550px;
           width: 43.5rem !important;
           padding-left: 0px;
           }
        
        .css08{
            width: 25% !important;
            display: inline-block;
        
        }

        .css09{
            padding-right: 20px;
        }
}